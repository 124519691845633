import { type LDClient, useLDClient } from 'launchdarkly-react-client-sdk'
import { useState } from 'react'
import { useAsync } from 'react-use'

const CONTEXT_CHECK_INTERVAL = 500

const waitForLDContextIdentification = async (
  ldClient: LDClient,
  interval = 1000
): Promise<boolean> =>
  new Promise(resolve => {
    const checkContext = async () => {
      const context = await ldClient.getContext()

      if (context && !context.anonymous) {
        resolve(true)
      } else {
        setTimeout(checkContext, interval)
      }
    }

    checkContext()
  })

/**
 * Polls ldClient.getContext() until there is no { anonymous: true } in the response,
 * which means the context was identified and fresh flag data based on that context was fetched.
 * */
const useLDUserContextIdentified = () => {
  const ldClient = useLDClient()
  const [isIdentified, setIsIdentified] = useState(false)

  useAsync(async () => {
    if (ldClient && !isIdentified) {
      setIsIdentified(
        await waitForLDContextIdentification(ldClient, CONTEXT_CHECK_INTERVAL)
      )
    }
  }, [ldClient, isIdentified])

  return isIdentified
}

export default useLDUserContextIdentified
